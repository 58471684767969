import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieWrapperService } from '../../shared/services/cookie-wrapper.service';
import { ConstantService } from '../../shared/services/constant.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../shared/services/api.service';
import { AdvertService } from './advert.service';
import { UserService } from './user.service';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { mergeMap, map, filter, take, switchMap } from 'rxjs/operators';
import { IRouteParams } from '../interfaces/IRouterParams';
import {
  ReferralTransitionResponse,
  ReferralStatisticResponse,
  MissionInfo,
  MissionProgress,
  MissionRewardReceiveResponse,
  MissionInfoResponse,
  MarathonInfo,
  MarathonProgress,
  MarathonLeaderboardResponse,
  MarathonLeaderboard,
  MarathonLeaderboardWinnersResponse, MarathonLeaderboardWinners, MarathonProgressResponse, MarathonUserProgresses
} from '../interfaces/mission-info';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { isPlatformBrowser } from '@angular/common';
import { CONSTANT } from '../../shared/constant/CONSTANT';
import { UtilsService } from '../../shared/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class MissionService {
  private _missionsInfo = new ReplaySubject<MissionInfo[]>();
  public missionsInfo$: Observable<MissionInfo[]> = this._missionsInfo.asObservable();

  private _marathonInfo = new ReplaySubject<MarathonInfo>();
  public marathonInfo$: Observable<MarathonInfo> = this._marathonInfo.asObservable();

  private _userMarathonProgresses = new BehaviorSubject<MarathonProgressResponse>(null);
  public userMarathonProgresses$: Observable<MarathonProgressResponse> = this._userMarathonProgresses.asObservable();

  private _mainUserMarathonProgress = new BehaviorSubject<MarathonProgress>(null);
  public mainUserMarathonProgress$: Observable<MarathonProgress> = this._mainUserMarathonProgress.asObservable();

  private _marathonNominationWinners = new BehaviorSubject<MarathonLeaderboardWinners[]>(null);

  private _baseUrl = 'PlaykeyAPI.svc';
  private _constTime: any;
  private _constLand: any;
  private _constUrls: any;
  private _constRef: any;
  private readonly _constMissionType = CONSTANT.missionType;

  public id: string;

  constructor(
    private _cookie: CookieWrapperService,
    private _constant: ConstantService,
    private _advert: AdvertService,
    private _user: UserService,
    private _translate: TranslateService,
    private _api: ApiService,
    private _localize: LocalizeRouterService,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _utils: UtilsService,
  ) {
    this._constTime = this._constant.get('CONSTANT.time');
    this._constLand = this._constant.get('CONSTANT.kohortTypes.landingRegistrationDate');
    this._constUrls = this._constant.get('URLS');
    this._constRef = this._constant.get('CONSTANT.referral');
    this._getUserMissionsInfo().subscribe();
    this._updatesAfterUserLogout();

  }

  /**
   * Метод возвращает ссылку на страницу реферальной ссылки
   * @param id {number | string} id пользователя
   */
  public  getReferralLink(id: number | string) {
    return `https://welcome.playkey.net/${this._translate.currentLang}/lp/referral?ref=${id}`;
  }

  /**
   * Метод отправляющий запрос /referral/transition для сохранения информации о том, что
   * этот пользователь перешел по вот этой конкретной реферальной ссылке. Полученный в ответе referralId сохраняется в куку;
   * При регистрации пользователя referralId также передается в запросе и удаляется из кук
   * @param {string} token
   * @param {string} getParameters
   * @returns {Observable<boolean>}
   */
  public transition (token: string, getParameters: string): Observable<boolean> {
    return this._getTransition(token, getParameters)
      .pipe(
        map((data: ReferralTransitionResponse) => {
          if (token) {
            this.clearReferralId();
          } else {
            const refId = data.referral_id;
            if (refId) {
              this.id = data.referral_id.toString();
              this._cookie.put('referralId', this.id);
            }
          }
          return true;
        })
      );
  }

  public clearReferralId (): void {
    this._cookie.remove('referralId');
  }

  // метод установки юзеру реферальной ссылки
  public setActualUser (token: string): void {
    const refId = this._cookie.get('referralId');
    if (refId) {
      this._setUser(token, refId).subscribe(() => this.clearReferralId());
    }
  }

  public setReferralSystem (token: string, referralUserId: string): Observable<boolean> {
    const utmSource = 'utm_source=referralFarCry5';
    const utmCampaign = 'utm_campaign=referralFarCry5';
    const urmMedium = 'utm_medium=referral';
    const getParameters = `?${utmSource}&${utmCampaign}&${urmMedium}&utm_term=${referralUserId}`;
    return this.transition(token, getParameters);
  }

  public getReferralStatistic (): Observable<ReferralStatisticResponse> {
    return this._getStatistic();
  }

  public getUserMissionsProgresses (): Observable<MissionProgress[]> {
    return this._user.userInfo$.pipe(
      mergeMap((userInfo) => {
        if (Boolean(userInfo) && !userInfo.error.code) {
          const params = {
            lang: this._translate.currentLang,
            token: this._user.token,
          };
          return this._api.post(`${this._baseUrl}/user/missions/progress`, params);
        }
        return of(null);
      }),
      map((data) => Boolean(data) && !data.error.code ? data.missions_progresses : null)
    );
  }

  public getUserReferralMission (): Observable<MissionInfo> {
    return this.getMissionsByType(this._constMissionType.referral)
      .pipe(map(data => Boolean(data) ? data[0] : null));
  }

  public getUserReferrerMission (): Observable<MissionInfo> {
    return this.getMissionsByType(this._constMissionType.referrer)
      .pipe(map(data => Boolean(data) ? data[0] : null));
  }

  public getMissionsByType (missionType: string): Observable<MissionInfo[]> {
    return this._missionsInfo.pipe(
      map(missions => {
        if (Boolean(missions)) {
          return missions.filter(m => m.type === missionType);
        }
        return null;
      }));
  }

  public getMarathonNominationWinners(nomination: string): Observable<MarathonLeaderboardWinners> {
    return this._marathonNominationWinners.pipe(
      switchMap(winners => {
        if (winners) {
          return of(winners.find(lb => lb.mission_type_code === nomination));
        }
        return this._marathonInfo.pipe(
          filter((marathon) => Boolean(marathon?.isStarted)),
          mergeMap((marathon) => {
            const params = {
              lang: this._translate.currentLang,
              token: this._user.token,
              marathon_code: marathon.code
            };
            return this._api.post(`${this._baseUrl}/marathon/leaderboard/winners`, params);
          }),
          map((data: MarathonLeaderboardWinnersResponse) => {
            if (data && !data.error.code) {
              this._marathonNominationWinners.next(data.leaderboards);
            }
            return null;
          }),
          take(1)
        );
      }),
      map(winners => winners || [])
    );
  }

  public getMarathonLeaderboard(): Observable<MarathonLeaderboard[]> {
    return this._marathonInfo.pipe(
      filter((marathon) => Boolean(marathon?.isStarted)),
      mergeMap((marathon) => {
        const params = {
          lang: this._translate.currentLang,
          token: this._user.token,
          marathon_code: marathon.code
        };
        return this._api.post(`${this._baseUrl}/marathon/leaderboard`, params);
      }),
      map((data: MarathonLeaderboardResponse) => {
        let leaderboards = null;
        if (data && !data.error.code) {
          leaderboards = data.leaderboards;
        }
        return leaderboards;
      }),
      take(1)
    );
  }

  public getMarathonPeriodHtml(marathon?: MarathonInfo, withWrap: boolean = true): string {
    if (!marathon) {
      return '';
    }
    const periodHtml = this._translate.instant('marathon.period')
      .replace('%from%', this._parseDate(marathon.activeFrom))
      .replace('%to%', this._parseDate(marathon.activeTo));
    if (!withWrap) {
      return periodHtml.replace('<br>', '');
    }
    return periodHtml;
  }

  public init (data: IRouteParams): void {
    const token = this._cookie.get('token');
    this._initDefaultReferral(token, data);
    this._initReferralSystemPk(token, data);
    this._setReferralCookie(data);
  }

  public getUserMarathonProgresses(): Observable<MarathonUserProgresses> {
    return this.marathonInfo$.pipe(
      filter((marathonInfo) => Boolean(marathonInfo)),
      switchMap((marathonInfo) => {
        return combineLatest([of(marathonInfo), this._userMarathonProgresses]);
      }),
      take(1),
      mergeMap(([marathonInfo, userMarathonProgresses]) => {
        if (Boolean(userMarathonProgresses)) {
          return of(userMarathonProgresses);
        }
        const params = {
          lang: this._translate.currentLang,
          token: this._user.token,
          marathon_code: marathonInfo.code
        };
        return this._api.post(`${this._baseUrl}/user/marathon/progress`, params);
      }),
      map((userMarathonProgress) => {
        const progresses = userMarathonProgress?.marathon_progress?.length >  0 ? userMarathonProgress : null;
        this._userMarathonProgresses.next(progresses);
        let mainUserMarathonProgress = null;
        if (progresses?.marathon_progress?.length > 0) {
          mainUserMarathonProgress = this._findMainUserMarathonProgress(progresses.marathon_progress);
        }
        this._mainUserMarathonProgress.next(mainUserMarathonProgress);
        return ({
          marathon_progress: progresses?.marathon_progress,
          marathon_main_progress: mainUserMarathonProgress
        });
      })
    );
  }

  private _getUserMissionsInfo (): Observable<MissionInfo[]> {
    // Подписка на userInfo$ необходима,
    // т.к. список миссий для не авторизованных пользователей не включает в себя реферальную миссию
    return this._user.userInfo$.pipe(
      map((data: MissionInfoResponse) => {
        this._marathonInfo.next(this._prepareMarathonInfo(data));

        let missions = null;
        if (data && !data.error.code) {
          missions = data.missions.map(mission => this._prepareMissionsInfo(mission));
        }
        this._missionsInfo.next(missions);
        return missions;
      }),
      take(1)
    );
  }

  private _prepareMarathonInfo(data): MarathonInfo {
    const marathon = data?.marathon;
    if (Boolean(marathon)) {
      if (Boolean(marathon?.missions)) {
        marathon.missions = marathon.missions.map(mission => this._prepareMissionsInfo(mission));
      }
      const activeFrom = this._utils.convertDateFromMoscowToLocalTimestamp(
        this._utils.filter(marathon.activeFrom, 'yyyy/dd/mm hh:mm'));
      marathon.isStarted = activeFrom < Date.now();
      const activeTo = this._utils.convertDateFromMoscowToLocalTimestamp(
        this._utils.filter(marathon.activeTo, 'yyyy/dd/mm hh:mm'));
      marathon.isFinished = Date.now() > activeTo;
    }
    return marathon || null;
  }

  private _parseDate(dateStr: string): string {
    if (!dateStr) {
      return '';
    }
    const parts = dateStr.split('-');
    const dateObj = {
      day: parts[0],
      month: parts[1]
    };
    return this._translate.instant(`decOfNum.month_${dateObj.month}`)
      .replace('%day%', dateObj.day);
  }

  private _prepareMissionsInfo(mission: MissionInfo): MissionInfo {
    mission.rules.forEach(rule => {
      if (rule.type === this._constRef.ruleType.buyDeposit) {
        rule.value = Number(rule.value);
      }
    });
    mission.rewards.forEach(reward => {
      if (reward.type === this._constRef.rewardType.playMinutes) {
        reward.value = Number(reward.value);
      }
    });
    return mission;
  }

  private _initDefaultReferral (token: string, search: IRouteParams): void {
    const utmSource = search.utm_source;
    const utmCampaign = search.utm_campaign;
    const utmContent = search.utm_content;
    const utmTerm = search.utm_term;

    if (utmSource && isPlatformBrowser(this._platformId)) {
      const parts: string[] = location.search.split('?');
      const getParameters: string = parts.length > 1
        ? parts[1]
        : '';
      if (getParameters !== '') {
        this.transition(token, getParameters).subscribe();
      }
      if (utmCampaign && this._advert.needSave(utmSource, utmCampaign)) {
        this._advert.save({
          source:     utmSource,
          campaign:   utmCampaign,
          content:    utmContent,
          term:       utmTerm,
        });
        this._cookie.put('utm_source', utmSource, this._constTime._advert);
        this._cookie.put('partnerCode', utmSource);
        this._cookie.put('referralLink', location.search, this._constTime.year);
      }
    }
  }

  private _initReferralSystemPk (token: string, data: IRouteParams): void {
    const referralUserId: string = data.user_id;
    if (referralUserId !== undefined) {
      this.setReferralSystem(token, referralUserId).subscribe();
    }
  }

  private _setReferralCookie (urlParams: IRouteParams): void {
    if (urlParams.utm_content) {
      this._cookie.put('utm_content', urlParams.utm_content, this._constTime.day);
    }
    if (urlParams.utm_term) {
      this._cookie.put('utm_term', urlParams.utm_term, this._constTime.day);
    }
    if (urlParams.utm_campaign) {
      this._cookie.put('utm_campaign', urlParams.utm_campaign, this._constTime.advert);
    }
  }

  private _getTransition ( token: string, getParameters: string): Observable<ReferralTransitionResponse> {
    const url = token
      ? `${this._baseUrl}/user/referral/transition` // авторизированый пользователь
      : `${this._baseUrl}/referral/transition`; // не авторизированый пользователь
    const params  = {
      lang: this._translate.currentLang,
      url_transition: getParameters,
      token: ''
    };
    if (token) {
      params.token = token;
    }
   return  this._api.post(url, params);
  }

  private _getStatistic (): Observable<ReferralStatisticResponse> {
    const url = `${this._baseUrl}/user/referrer/statistic`;
    const params = {
      lang: this._translate.currentLang,
      token: this._user.token,
    };
    return this._api.post(url, params);
  }

  // запрос на сервер на добавления  юзеру реферальной ссылки
  private _setUser (token: string, refId: string): Observable<ReferralTransitionResponse> {
    const url = `${this._baseUrl}/user/referral/set`;
    const params = {
      lang: this._translate.currentLang,
      token: token,
      referral_id: refId,
    };
    return  this._api.post(url, params).pipe(map(requestData => requestData.data));
  }

  private _findMainUserMarathonProgress(progresses: MarathonProgress[]) {
    return progresses?.reduce((prev, curr) => {
      if (prev.position === 0) {
        return curr;
      }
      if (curr.position === 0) {
        return prev;
      }
      if (prev.position === curr.position) {
        return prev.position_change > curr.position_change ? prev : curr;
      }
      return prev.position < curr.position ? prev : curr;
    });
  }

  private _updatesAfterUserLogout() {
    this._user.logoutEvent$
      .pipe(switchMap(() => {
        this._userMarathonProgresses.next(null);
        this._mainUserMarathonProgress.next(null);
        return this._getUserMissionsInfo();
      }))
      .subscribe(() => {});
  }
}
