import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../shared/services/api.service';
import { KohortService } from './kohort.service';
import { CookieWrapperService } from '../../shared/services/cookie-wrapper.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { IAuthResponse } from '../interfaces/auth-response';
import { IUserInfo } from '../interfaces/user-info';
import { IApiResponse } from '../../interfaces/api-response';
import { IPassChangeResponse } from '../interfaces/pass-change-response';
import { IKohort } from '../../interfaces/kohorts';
import { IUserContract } from '../interfaces/IUserContract';
import { IErrorResponse } from '../../interfaces/error';
import { AnalyticService } from '../../shared/services/analytic.service';
import { IPushTokenResponse } from '../interfaces/push-token-response';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { SteamApiService } from '../../shared/modules/platforms-connect/steam/services/steam-api.service';
import { GTMDataLayerParams } from '../../shared/interfaces/analytic';

@Injectable({
  providedIn: 'root'
})
/**
 * Для проверки авторизации используем метод checkAuth(). Пример:
 *  this._user.checkAuth()
 *    .pipe(
 *      mergeMap(data =>...)
 *    );
 */
export class UserService {
  private baseUrl = `${environment.apiPathPrimary}user`;

  private dataSubject = new BehaviorSubject<IUserInfo>(null);

  private _userInfo: IUserInfo;

  private logoutEvent = new Subject<any>();

  public userInfo$: Observable<IUserInfo> = this.dataSubject.asObservable();

  public logoutEvent$: Observable<any> = this.logoutEvent.asObservable();

  public token: string;

  public detectedRegion: any;

  public isInited = false;


  constructor(
    private _api: ApiService,
    private _cookie: CookieWrapperService,
    private _kohort: KohortService,
    private _translate: TranslateService,
    private _analytic: AnalyticService,
    private _steamApi: SteamApiService,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    this._init();
    this.getUserRegionFromStorageOrInfoIp();
  }

  // метод пушает новый стэйт когда юзер вышел из акаунта
  public logoutNext(state: boolean): void {
    this.logoutEvent.next(state);
  }

  public getCurrentUserInfo() {
    return this.dataSubject.getValue();
  }

  getUserInfo(token?: string): Observable<IUserInfo> {
    this._removeUserCookie();
    this._userInfo = null;
    this.token = null;
    if (this.token || Boolean(token)) {
      return this._api.post(`${this.baseUrl}/info`, {
        lang: this._translate.currentLang || this._cookie.get('lang'),
        token: token || this.token
      }).pipe(
        map(data => {
          if (!data.error.code) {

            // Проверим партнёров, если это пользователь партнёра, для которого есть отдельный портал - редиректим
            if (isPlatformBrowser(this._platformId)) {
              const partnerUrls = environment.partnerUrls;
              data.partners.forEach((partner) => {
                if (Boolean(partnerUrls[partner])) {
                  let url = partnerUrls[partner];
                  if (window.location.href.indexOf('set_pass') > -1 || !window.location.href.includes('auth/swap')) {
                    url = window.location.href.replace(window.location.origin, partnerUrls[partner]);
                  }
                  this._removeUserCookie();
                  window.location.href = url;
                }
              });
            }

            // Если передан токен - возвращаются данные, без смены пользователя
            if (token) {
              return data;
            }

            this.isInited = true;
            this.dataSubject.next(data);
            this._cookie.put('user_id', data.id.toString());
            const region = this._getUserRegionFromStorage();
            if (region !== data.region_code) {
              this._saveUserRegionToStorage(data.region_code);
            }
            if (isPlatformBrowser(this._platformId)) {
              this._sendAnalytics(data);
            }
          } else if (isPlatformBrowser(this._platformId) && Boolean(this.token)) {
            this.logout();
          }
          return data;
        }),
        take(1)
      );
    } else {
      this.isInited = true;
      this.dataSubject.next(null);
      return of(null);
    }
  }

  logout() {
    this._removeUserCookie();
    this.dataSubject.next(null);
    this.token = null;
    this._userInfo = null;
    this.logoutNext(true);
  }

  auth(login: string, password: string, captchaResponse?: string): Observable<IAuthResponse> {
    // https://jira.dev.playkey.net/browse/CLOUDGAMES-18578
    // fake request
    return this._api.post(`${this.baseUrl}/auth`, {
      lang: this._translate.currentLang,
      login: login,
      pass: password,
      captcha_response: captchaResponse,
      kohort_transition_ids: this._kohort.getTransitions()
    })
      .pipe(
        mergeMap(() => {
          return this._api.post(`${environment.apiPathPrimary}games/all`, {
            lang: this._translate.currentLang,
            login: login,
            pass: password,
            captcha_response: captchaResponse,
            kohort_transition_ids: this._kohort.getTransitions()
          });
        }),
        map((data) => {
          const gtmParams: GTMDataLayerParams = {
            event: 'user',
            email: login,
            logged_in: true,
            userId: data.access_token,
          };

          this._analytic.dataLayerPush(gtmParams);
          return data;
        })
      );
  }

  authSwap(login: string, password: string, extUserId: number, captchaResponse?: string):
    Observable<{user: string, email: string} & IApiResponse> {
    return this._api.post(`${environment.apiPathPrimary}users/vkpc/auth`, {
      lang: this._translate.currentLang,
      login: login,
      pass: password,
      ext_user_id: extUserId,
      captcha_response: captchaResponse,
      kohort_transition_ids: this._kohort.getTransitions()
    })
      .pipe(
        map((data) => {
          return data;
        }), catchError(err => of(err)),
      );
  }

  setToken(value: string, dateExpires) {
    this._cookie.put('token', value, moment(dateExpires, 'DD-MM-YYYY HH:mm:ss').toDate());
    // Кука используется для определния авторизовывался ли пользователь ранее
    this._cookie.put('isAuth', 'true');
    this.token = value;
    return this.getUserInfo();
  }

  registration(login?: string, password?: string, captchaResponse?: string): Observable<IAuthResponse> {
    const referralId = this._cookie.get('referralId');
    return this._api.post(`${this.baseUrl}/registration`, {
      lang: this._translate.currentLang,
      email: login,
      pass: password,
      captcha_response: captchaResponse,
      referral_id: referralId,
      kohort_transition_ids: this._kohort.getTransitions()
    })
      .pipe(
        map((data) => {
          if (!data.error.code) {
            this._cookie.remove('referralId');
            const gtmParams: GTMDataLayerParams = {
              event: 'user',
              logged_in: true,
              userId: data.access_token,
            };
            if (Boolean(login)) {
              gtmParams.email = login;
              this._sendShareASale(data.user_id);
            }
            this._analytic.dataLayerPush(gtmParams);
            this._analytic.reachGoal('registration');
          }
          return data;
        })
      );
  }

  setEmail(email: string, captchaResponse?: string): Observable<IAuthResponse> {
    return this._api.post(`${this.baseUrl}/anonymous/setemail`, {
      lang: this._translate.currentLang,
      email: email,
      captcha_response: captchaResponse,
      token: this.token
    })
      .pipe(
        map((data: IAuthResponse) => {
          if (this._userInfo) {
            this._sendShareASale(this._userInfo.id);
          }
          return data;
        })
      );
  }

  recoveryPassword(email: string, captchaResponse?: string): Observable<IApiResponse> {
    return this._api.post(`${this.baseUrl}/pass/recall`, {
      lang: this._translate.currentLang,
      email: email,
      captcha_response: captchaResponse
    });
  }

  setPassword(password: string, token: string): Observable<IAuthResponse> {
    return this._api.post(`${this.baseUrl}/pass/set`, {
      lang: this._translate.currentLang,
      token: token,
      new_pass: password
    });
  }

  changePassword(oldPassword: string, newPassword: string): Observable<IPassChangeResponse> {
    return this._api.post(`${this.baseUrl}/pass/change`, {
      lang: this._translate.currentLang,
      token: this.token,
      old_pass: oldPassword,
      new_pass: newPassword
    });
  }

  confirmEmail(token: any): Observable<IApiResponse> {
    return this._api.post(`${this.baseUrl}/confirm`, {
      lang: this._translate.currentLang,
      token: token
    });
  }

  public requestConfirmMail(): Observable<IErrorResponse> {
    const url = `${this.baseUrl}/requestConfirmMail`;
    const params = {
      lang: this._translate.currentLang,
      token: this.token,
    };
    return this._api.post(url, params);
  }

  setKohortToUser(kohorts: IKohort[]) {
    return this._kohort.addKohorts(kohorts)
      .pipe(
        mergeMap(data => {
          const kohortTransitions = data.added_kohorts.map(kohort => kohort.transition_id);
          return this._api.post(`KohortsAPI.svc/user/kohorts/set`, {
            lang: this._translate.currentLang,
            token: this.token,
            kohort_transition_ids: kohortTransitions
          });
        })
      );
  }

  checkAuth(): Observable<boolean> {
    return this.userInfo$.pipe(map(data => Boolean(data)));
  }

  private _init() {
    this._removeUserCookie();
    this._userInfo = null;
    this.token = null;
    this.token = this._cookie.get('token');
    if (Boolean(this.token)) {
      this.getUserInfo().subscribe(data => {
        this._userInfo = data;
      });
    } else {
      this.dataSubject.next(null);
      this.isInited = true;
    }
  }

  public getUserContractAddress(): Observable<IUserContract> {
    const url = `${this.baseUrl}/contract/address`;
    return this._api.post(url, {
      lang: this._translate.currentLang,
      token: this.token,
    });
  }

  public removeAccount(): Observable<boolean> {
    const params = {
      lang: this._translate.currentLang,
      token: this.token,
    };
    const url = `${this.baseUrl}/delete`;
    return this._api.post(url, params)
      .pipe(
        map((response: any) => {
            if (!response.error.code) {
              return true;
            } else {
              return false;
            }
          }
        ));
  }

// метод применяется при удалении Аккаунта
  public logoutAfterDelete(userInfo): void {
    this._removeUserCookie();
  }

  public isCis(): boolean {
    return this.getDetectedRegion() === 'CIS';
  }

  public detectEuRegion(): boolean {
    if (this.detectedRegion) {
      return this.detectedRegion;
    } else {
      const detectedRegions: string[] = [
        'UnitedKingdom',
        'World',
        'Europe',
      ];

      if (detectedRegions.indexOf(this.getDetectedRegion()) > -1) {
        this.detectedRegion = true;
        return true;
      }
    }
    return false;
  }

  public getDetectedRegion(): string {
    const regionCode = this._getUserRegionFromStorage();
    if (!regionCode) {
      if (this._userInfo) {
        if (this._userInfo.region_code) {
          return this._userInfo.region_code;
        }
      } else {
        return 'World';
      }
    }
    return regionCode;
  }

  // Асинхронный метод получения региона
  // Метод получения региона пользователя
  public asyncgetDetectedRegion(): Observable<string> {
    const isCookieRegion = this._getUserRegionFromStorage();
    // Проверяем есть ли регион в куки (приоритет у нее)
    if (isCookieRegion) {
      return of(isCookieRegion);
    } else {
      // Если регион не найден в куки берем с Инфо Пользователя
      return this.checkAuth()
        .pipe(
          mergeMap((data) => {
            return data ? this.userInfo$ : of(null);
          }),
          map((data: IUserInfo) => {
            if (!data) {
              return 'CIS'; // Выдаем регион, если другого нет
            } else {
              return data.region_code;
            }
          })
        );
    }
  }

  /**
   * Выявление региона пользователя из куки, либо запросом к методу infoIp
   */
  public getUserRegionFromStorageOrInfoIp() {
    const regionCodeFromCookie = this._getUserRegionFromStorage();
    if (Boolean(regionCodeFromCookie)) {
      return of(regionCodeFromCookie);
    } else {
      this._saveUserRegionToStorage('CIS')
      return 'CIS';
    }
  }

  /**
   * Получение region_code из infoIp
   * @private
   */
  private _getUserRegionFromInfoIp() {
    return this._api.get(`${environment.apiPathPrimary}infoip`).pipe(
      map(data => data.region_code),
    );
  }

  /**
   * Получение региона пользователя из хранилища
   * @private
   */
  private _getUserRegionFromStorage() {
    return this._cookie.get('region_code');
  }

  /**
   * Сохранение региона пользователя в хранилище
   * @param region_code
   * @private
   */
  private _saveUserRegionToStorage(region_code) {
    this._cookie.put('region_code', region_code);
  }

  isUserAnonymous(): boolean {
    return (this._userInfo && this._userInfo.is_anonymous) || false;
  }

  getPushToken(): Observable<IPushTokenResponse> {
    return this._api.post(`${this.baseUrl}/pushtoken`, {
      lang: this._translate.currentLang,
      token: this.token
    });
  }

  private _sendAnalytics(data: IUserInfo) {
    this._analytic.sendUserID(data.id.toString());
    const gtmParams: GTMDataLayerParams = {
      event: 'user',
      logged_in: true,
      userId: data.id,
    };

    if (data.is_anonymous === false && data.email !== null) {
      gtmParams.email = data.email;
    }
    this._analytic.dataLayerPush(gtmParams);

    try {
      this._yaMetricAddUID();
    } catch (e) {
      console.warn('Ya metrica locked');
    }
  }

  /**
   * Метод отправки данных в ShareASale
   * @param userId
   * @private
   */
  private _sendShareASale(userId: number): void {
    const ssCid = this._cookie.get('shareSaleCid');
    const needSendEvent = this._cookie.get('sendShareSaleReg');
    if (Boolean(ssCid) && Boolean(needSendEvent)) {
      this._analytic.dataLayerPush({
        event: 'event',
        eventCategory: 'user',
        eventAction: 'user from ShareASale registrated',
        userId: userId,
        ssCid,
      });
      this._cookie.remove('sendShareSaleReg');
    }
  }

  private _yaMetricAddUID() {
    const w = (<any>window);
    const ym = w.yaCounter;

    const params = {
      yandex_client_id: ym.getClientID(),
      lang: this._translate.currentLang || this._cookie.get('lang'),
      token: this.token,
    };

    this._api.post(`${this.baseUrl}/yandexmetrics/add`, params)
      .subscribe();
  }

  private _removeUserCookie() {
    this._cookie.remove('token');
    this._cookie.remove('email');
    this._cookie.remove('user_id');
    this._cookie.remove('user_tariff_code');
    this._cookie.remove('got_referral_link');
    this._cookie.remove('isAuth');
    if (!this.isUserAnonymous()) {
      this._cookie.remove('kohort_code');
      this._cookie.remove('kohort_codes_add');
      this._cookie.remove('kohort_transitions');
      this._cookie.remove('kohort_transitions_add');
    }
  }

  public openTermsOfUse(): void {
    window.open('https://documentation.vkplay.ru/terms_vkp/tou_vkp', '_blank');
  }

  public openPrivacyPolicyCIS(): void {
    window.open('https://documentation.vkplay.ru/terms_vkp/privacy_vkp', '_blank');
  }

  public openPrivacyPolicyNotCIS(): void {
    window.open('https://documentation.vkplay.ru/terms_vkp/privacy_world_vkp', '_blank');
  }

  public openImprint(): void {
    window.open('https://documentation.vkplay.ru/terms_vkp/imprint_vkp', '_blank');
  }

  public openPrivacyPolicyForChildren(): void {
    window.open('https://documentation.vkplay.ru/terms_vkp/privacy_world_child_vkp', '_blank');
  }

  public openPrivacyPolicyCookie(): void {
    window.open('https://documentation.vkplay.ru/terms_vkp/cookie_world_vkp', '_blank');
  }
}
