import { Injectable } from '@angular/core';
import { UserService } from '../../user/services/user.service';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { delay, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from '../../shared/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { IPaymentSystem, IPaymentSystemResponse } from '../interfaces/payment-system';
import { IBindPsResponse } from '../interfaces/bind-ps-response';
import { CurrencyService } from '../../shared/services/currency.service';
import { IUserPayment, IUserPaymentResponse } from '../interfaces/user-payment-system';
import { IApiResponse } from '../../interfaces/api-response';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ISubscription } from '../../user/interfaces/subscription';
import { IError } from '../../interfaces/error';
import { IBundleInfo, IBundleInfoResponse } from '../interfaces/bundle-info';
import { Product } from '../../games/interfaces/product';
import { DynamicService } from '../../modal/service/dynamic.service';
import { ProductService } from './product.service';
import { SubscriptionService } from '../../user/services/subscription.service';
import { Params, Router, UrlTree } from '@angular/router';
import { ITimePacket } from '../../interfaces/time-packet';
import { ISaleStatus } from '../interfaces/ISaleStatus';
import { IUserSales } from '../interfaces/IUserSales';
import { UtilsService } from '../../shared/services/utils.service';
import { ResumeSubscribeService } from '../../shared/services/resume-subscribe.service';
import { IProducts } from '../interfaces/IProducts';
import { AnalyticService } from '../../shared/services/analytic.service';
import { GamesKeysResponse } from '../../games/interfaces/games-keys-response';
import { GamesKey } from '../../games/interfaces/games-key';
import { GameShipment } from '../../games/interfaces/game-shipment';
import { GamesService } from '../../shared/services/games.service';
import { PLATFORMS } from '../../shared/constant/PLATFORMS';
import { RouterEventsService } from '../../shared/services/router-events.service';
import { CONSTANT } from '../../shared/constant/CONSTANT';
import { ERRORCODE } from '../../shared/constant/ERRORCODE';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private _baseUrl = environment.apiPathPrimary;

  // Observable получение платежных систем пользователя
  private _dataSubject = new ReplaySubject<IUserPayment[]>(1);

  public userPs$: Observable<IUserPayment[]> = this._dataSubject.asObservable();

  // Observable получения  всех платежных систем
  private _allPs = new ReplaySubject<IPaymentSystem[]>(1);

  public allPs$: Observable<IPaymentSystem[]> = this._allPs.asObservable();

  public selectedPs = 'AlfaBank';

  private _sales = new ReplaySubject<IUserSales[]>(1);

  public userSales$: Observable<IUserSales[]> = this._sales.asObservable();

  public isLoading = false;

  public showPaymentLoader = false;

  private _isCompleteReferralMission = '0';

  // Платежные данные пользователя
  public userPaymentData: IUserPayment[];

  private _gameKey: string;


  constructor(private _user: UserService,
              private _translate: TranslateService,
              private _analytic: AnalyticService,
              private _currency: CurrencyService,
              private _localize: LocalizeRouterService,
              private _router: Router,
              private _dynamic: DynamicService,
              private _product: ProductService,
              private _subscription: SubscriptionService,
              private _resumeSubscribe: ResumeSubscribeService,
              private _sub: SubscriptionService,
              private  _utils: UtilsService,
              private  _game: GamesService,
              private _api: ApiService,
              private _routerEvents: RouterEventsService) {
  }

  /**
   * Метод смены подписки и привязки платежной системы
   * @param subscriptionId - идентификатор текущей подписки пользователя
   * @param productId - идентификатор выбранной подписки
   * @param isChangeNow - флаг смены подписки прямо сейчас
   * @param returnUrl - url для перехода после оплаты
   */
  changeAndBind(subscriptionId: number, productId: string, isChangeNow: boolean = false,
                returnUrl?: string, cardName?: string, cryptogram?: string) {
    returnUrl = returnUrl || `${window.location.origin}/${this._translate.currentLang}/steps/payment`;
    if (Boolean(this._game.userLastGameCardVisited)) {
      returnUrl = `${window.location.origin}/${this._translate.currentLang}/game/${this._game.userLastGameCardVisited.code}`;
    }
    this._api.post(`${this._baseUrl}user/subscriptions/changeandbind`, {
      lang: this._translate.currentLang,
      token: this._user.token,
      code_product_shipment: productId,
      code_payment_system: this.selectedPs,
      return_url: returnUrl,
      is_change_now: isChangeNow,
      subscription_id: subscriptionId,
      card_name: cardName,
      card_cryptogram_packet: cryptogram
    })
      .pipe(
        map((data: IBindPsResponse) => {
          if (!data.error.code) {
            this._openForm(data.form_url, null, isChangeNow);
          } else {
            this._catchError(data.error);
          }
        })
      ).subscribe();
  }

  /**
   * Метод смены подписки без привязки пс
   * @param subscriptionId
   * @param productId
   * @param isChangeNow
   * @param isCancel
   */
  change(subscriptionId: number, productId: string, isChangeNow: boolean = false, isCancel: boolean = false) {
    this.showPaymentLoader = true;
    this._api.post(`${this._baseUrl}user/subscriptions/change`, {
      lang: this._translate.currentLang,
      token: this._user.token,
      code_product_shipment: productId,
      is_change_now: isChangeNow,
      subscription_id: subscriptionId
    })
      .pipe(
        map((data: IApiResponse) => {
          if (!data.error.code) {
            return true;
          } else {
            this._catchError(data.error);
            return false;
          }
        }),
        delay(2000), // добавил задержку, так как без нее после change в subscriptions приходит старая подписка
        mergeMap((data) => data ? this._subscription.get() : of([]))
      )
      .subscribe((data) => {
        if (data.length) {
          const handleAfterRemoveDynamicComponent = () => {
            if (!this._routerEvents.getCurrUrl() || this._routerEvents.getCurrUrl().indexOf('steps/payment') > -1) {
              this._router.navigate([this._localize.translateRoute('/games')]);
            }
          };
          if (Boolean(this._game.userLastGameCardVisited)) {
            this._router.navigateByUrl(
              this._getGameCardOnboardingSuccessUrlTree(this._game.userLastGameCardVisited.code,
                {status: 'success', type: 'pay'})
            );
          } else if (Boolean(data[0].name_tariff_to_change)) {
            this._dynamic.addDynamicComponent({
              type: 'notify', data: {
                title: this._translate.instant('modal.changeSuccessTitle'),
                text: this._translate.instant('modal.changeSuccessDescription')
                  .replace(
                    '%tariffName%',
                    data[0].name_tariff_to_change)
                  .replace(
                    '%date%',
                    this._utils.filter(data[0].active_to, 'dd.mm.yyyy hh:mm'))
              }
            }, handleAfterRemoveDynamicComponent);
          } else {
            this._dynamic.addDynamicComponent({
              type: 'notify',
              data: {
                title: this._translate.instant('payment.win'),
                text: isCancel ? this._translate.instant('payment.successCancel') : this._translate.instant('payment.successChange')
              }
            }, handleAfterRemoveDynamicComponent);
          }
        }
        this.showPaymentLoader = false;
      });
  }

  /**
   * Метод покупки подписки и привязки платежной системы
   * @param productId - идентификатор выбранной подписки
   * @param returnUrl - url для перехода после оплаты
   */
  activateAndBind(productId: string, returnUrl?: string, cardName?: string, cryptogram?: string) {
    returnUrl = returnUrl || `${window.location.origin}/${this._translate.currentLang}/steps/payment`;
    if (Boolean(this._game.userLastGameCardVisited)) {
      returnUrl = `${window.location.origin}/${this._translate.currentLang}/game/${this._game.userLastGameCardVisited.code}`;
    }

    this._api.post(`${this._baseUrl}user/subscriptions/activateandbind`, {
      lang: this._translate.currentLang,
      token: this._user.token,
      code_product_shipment: productId,
      code_payment_system: this.selectedPs,
      return_url: returnUrl,
      card_name: cardName,
      card_cryptogram_packet: cryptogram
    })
      .pipe(
        map((data: IBindPsResponse) => {
          if (!data.error.code) {
            this._openForm(data.form_url);
          } else {
            this._catchError(data.error);
          }
        })
      ).subscribe();
  }

  /**
   * Метод покупки подписки с текущей активной платежной системой
   * Используем также вместо buyWidthBalancePs
   * @param productId
   */
  activate(productId: string) {
    this.showPaymentLoader = true;
    if (this._product.selectedProduct.type !== CONSTANT.subscription.type.time) {
      this._analytic.pushTag([{
        buy: this._product.selectedProduct.sub_code,
      }]);
    } else {
      this._analytic.pushTag([{
        buytraining: this._product.selectedProduct.sub_code,
      }]);
    }
    return this._getUserPs()
      .pipe(
        take(1),
        switchMap(ps => Boolean(ps) ?
          this._api.post(`${this._baseUrl}user/subscriptions/activate`, {
            lang: this._translate.currentLang,
            token: this._user.token,
            code_product_shipment: productId,
            user_payment_id: ps.id,
          }) : of(null)
        ),
        mergeMap((data) => {
          if (Boolean(data)) {
            if (!data.error.code) {
              this._isCompleteReferralMission = data.is_complete_referral_mission ? '1' : '0';
              return this._subscription.get();
            } else {
              this._catchError(data.error);
              return of(null);
            }
          } else {
            return of(null);
          }
        })
      )
      .subscribe((data) => {
        if (Boolean(data)) {
          this.showPaymentLoader = false;
          if (Boolean(this._game.userLastGameCardVisited)) {
            this._router.navigateByUrl(
              this._getGameCardOnboardingSuccessUrlTree(this._game.userLastGameCardVisited.code,
                {status: 'success', type: 'pay'})
            );
          } else {
            this._router.navigate(
              [this._localize.translateRoute('/steps/success')],
              {queryParams: {is_complete_referral_mission: this._isCompleteReferralMission}}
            );
          }
        }
      });
  }

  // Метод повторной покупки текущей подписки пользователя с текущей активной платежной системой
  public resumeExist(productShipmentTypeCode: string) {
    return this._getUserPs()
      .pipe(
        take(1),
        mergeMap(ps => !ps ? of(null) :
          this._api.post(`${this._baseUrl}user/subscriptions/resume`, {
            lang: this._translate.currentLang,
            token: this._user.token,
            product_shipment_type_code: productShipmentTypeCode,
            user_payment_id: ps.id,
          })
        ),
        mergeMap((data) => {
          if (Boolean(data)) {
            if (!data.error.code) {
              return this._subscription.get();
            } else {
              this._catchError(data.error);
              return of(null);
            }
          } else {
            return of(null);
          }
        })
      );
  }

  // Метод повторной покупки текущей подписки пользователя с привязкой новой платежной системы
  public resumeAndBindExist(productShipmentTypCode: string, returnUrl?: string, cardName?: string, cryptogram?: string) {
    console.log('resumeAndBindExist');
    this._api.post(`${this._baseUrl}user/subscriptions/resumeandbind`, {
      lang: this._translate.currentLang,
      token: this._user.token,
      code_payment_system: this.selectedPs,
      return_url: `${window.location.origin}/${this._translate.currentLang}/steps/payment`,
      card_name: cardName,
      card_cryptogram_packet: cryptogram,
      product_shipment_type_code: productShipmentTypCode
    })
      .pipe(
        map((data: IBindPsResponse) => {
          if (!data.error.code) {
            this._openForm(data.form_url);
          } else {
            this._catchError(data.error);
          }
        })
      ).subscribe();
  }

  /**
   * Метод покупки оверлимита и привязки платежной системы
   * @param code
   * @param returnUrl
   * @param cardName
   * @param cryptogram
   */
  buyAndBindOverlimit(code: string, returnUrl?: string, cardName?: string, cryptogram?: string) {
    returnUrl = returnUrl || `${window.location.origin}/${this._translate.currentLang}/steps/payment`;

    this._api.post(`${this._baseUrl}user/subscriptions/buytimepacketandbind`, {
      lang: this._translate.currentLang,
      token: this._user.token,
      code_payment_system: this.selectedPs,
      return_url: returnUrl,
      code_play_time_packet: code,
      card_name: cardName,
      card_cryptogram_packet: cryptogram
    })
      .pipe(
        map((data: IBindPsResponse) => {
          if (!data.error.code) {
            this._openForm(data.form_url);
          } else {
            this._catchError(data.error);
          }
        })
      )
      .subscribe();
  }

  /**
   * Метод покупки оверлимита без привязки платежной системы
   * Используем также вместо buyOverLimitWidthBalancePs!
   * @param productId
   */
  buyTimePacket(productId: string): Observable<boolean> {
    return this._getUserPs()
      .pipe(
        take(1),
        mergeMap(ps => Boolean(ps) ?
          this._api.post(`${this._baseUrl}user/subscriptions/buytimepacket`, {
            lang: this._translate.currentLang,
            token: this._user.token,
            user_payment_id: ps.id,
            code_play_time_packet: productId,
          }) :
          of(null)),
        map((data: IApiResponse) => {
          if (Boolean(data)) {
            if (!data.error.code) {
              return true;
            } else {
              this._catchError(data.error);
            }
          }
          return false;
        })
      );
  }

  /**
   * Метод покупки игры (карта при этом не привязывается!)
   * @param shipmentId
   * @param game
   */
  buyGame(shipmentId: number, game: string, cardName?: string, cryptogram?: string) {
    let returnUrl = `${window.location.origin}/${this._translate.currentLang}/steps/payment?game=${game}`;
    if (Boolean(this._game.userLastGameCardVisited)) {
      returnUrl = `${window.location.origin}/${this._translate.currentLang}/game/${this._game.userLastGameCardVisited.code}`;
    }

    this._api.post(`${this._baseUrl}user/games/buyandbind`, {
      lang: this._translate.currentLang,
      token: this._user.token,
      code_payment_system: this.selectedPs,
      return_url: returnUrl,
      id_game_shipment: shipmentId,
      card_name: cardName,
      card_cryptogram_packet: cryptogram,
    })
      .pipe(
        map((data: IBindPsResponse) => {
          if (!data.error.code) {
            this._openForm(data.form_url, game);
          } else {
            this._catchError(data.error, `/game/${game}`);
          }
        })
      )
      .subscribe();
  }

  /**
   * Метод покупки бандла для пользователей с непривязанной платёжной системой
   * @param {string} bundleCode Код бандла
   * @param {string} returnUrl Урл на который вернется пользователь после оплаты
   */
  bundleBuyAndBind(bundleCode: string, returnUrl?: string, cardName?: string, cryptogram?: string) {
    returnUrl = returnUrl || `${window.location.origin}/${this._translate.currentLang}/steps/payment?bundle=${bundleCode}`;

    this._api.post(`${this._baseUrl}bundle/buyandbind`, {
      lang: this._translate.currentLang,
      bundle_code: bundleCode,
      code_payment_system: this.selectedPs,
      token: this._user.token,
      return_url: returnUrl,
      card_name: cardName,
      card_cryptogram_packet: cryptogram,
    })
      .pipe(
        map((data: IBindPsResponse) => {
          if (!data.error.code) {
            this._openForm(data.form_url);
          } else {
            this._catchError(data.error);
          }
        })
      ).subscribe();
  }

  /**
   * Получить информацию о бандлах.
   * *token* - токен пользователя:
   - если не задан, возвращаем все активные бандлы, которые доступны всем пользователям (без учёта когорт)
   - если задан, возвращаем все активные бандлы, которые доступны всем пользователям + бандлы. доступные только для когорт пользователя
   *
   * @param {string[]} filterCodes список кодов бандлов.
   * - Если пуст - возвращаем все активные бандлы (учитывая параметр *token*)
   * - Если не пуст - возвращаем все активные бандлы по кодам (учитывая параметр
   * @param {string[]} filterKohorts список кодов когорт.
   * - Если пуст - возвращаем все активные бандлы (учитывая параметр *token*)
   * - Если не пуст - возвращаем все активные бандлы, которые достпуны только для
   * @returns {Observable<IBundleInfoResponse>}
   */
  getBundleInfo(filterCodes?: string[], filterKohorts?: string[]): Observable<IBundleInfoResponse> {
    return this._user.checkAuth()
      .pipe(
        mergeMap(data => {
          const url = data ? 'bundle/all/auth' : 'bundle/all';
          return this._api.post(`${this._baseUrl}${url}`, {
            lang: this._translate.currentLang,
            token: this._user.token,
            filter_codes: filterCodes,
            filter_kohorts: filterKohorts
          });
        }),
        map((data: IBundleInfoResponse) => {
          if (!data.error.code) {
            return data;
          } else {
            return null;
          }
        })
      );
  }

  /**
   * Метод проверяет возможность смены подписки
   */
  canChangeSubscription(subscriptions: ISubscription[], regularSubscription: ISubscription, productType: string): boolean {
    const timeType = CONSTANT.subscription.type.time;

    if (Boolean(subscriptions) && subscriptions.length) {
      return subscriptions[0].type !== timeType &&
        Boolean(regularSubscription) && productType !== timeType;
    }
    return false;
  }

  /**
   * @param getIsChangeNow - получить цену для смены сейчас
   * @returns {number}
   */
  public getProductPriceSellInPkt(getIsChangeNow: boolean = false): number {
    const gwei = this._product.selectedProduct.costs.filter(cost => cost.currency.code === 'PKT');

    if (gwei.length) {
      if (Boolean(gwei[0]) && !getIsChangeNow) {
        return gwei[0].price_sell / Math.pow(10, 18);
      } else if (gwei[0] !== undefined && getIsChangeNow) {
        return gwei[0].change_now_price / Math.pow(10, 18);
      }
    }
    return null;
  }

  /**
   * вернет стоимость продукта сконвертированную в рубли из ПКТ (округляем до большего значения)
   * @returns {number}
   */
  public convertPktToRuble(): Observable<number> {
    return this._currency.data$
      .pipe(
        take(1),
        mergeMap(data => Boolean(data) && data.length ?
          this._currency.getRate('PKT', data[0].iso_code) : of(null)),
        map(data => {
          if (Boolean(data)) {
            return Math.ceil(data * this.getProductPriceSellInPkt());
          } else {
            return null;
          }
        })
      );
  }

  /**
   * Проверим может ли продукт быть оплачен за ПКТ
   * Игры за PKT пока не продаем!
   * @returns {boolean}
   */
  public canBuyProductWidthBalancePs(): Observable<boolean> {
    return this._user.checkAuth()
      .pipe(
        map(data => {
          if (data) {
            return Boolean(this._product.selectedProduct.costs.filter(cost => cost.currency.code === 'PKT').length);
          }
          return false;
        })
      );
  }

  /**
   * Метод, проверяющий хватит ли PKT у юзера для покупки данного продукта
   * @param isChangeNow
   * @returns {Observable<boolean>}
   */
  public userCanPayPkt(isChangeNow: boolean = false): Observable<boolean> {
    return this._getUserPs('PKTBalance')
      .pipe(
        map(data => {
          if (Boolean(data)) {
            const balance = data.payment_system.current_balance;
            return Boolean(balance) && this.getProductPriceSellInPkt(isChangeNow) <= balance / Math.pow(10, 18);
          }
          return false;
        })
      );
  }

  /**
   * Метод оформления подписки
   * @param changeNow - параметр, описывающий необходимость сменить подписку прямо сейчас
   */
  selectTariff(changeNow: boolean = false) {
    this._router.navigate([this._localize.translateRoute('/union')]);
  }

  /**
   * Метод покупки/смены тарифа с привязкой карты
   * @param changeNow
   * @param cardName
   * @param cryptogram
   */
  buyTariff(changeNow: boolean = false, cardName?: string, cryptogram?: string) {
    if (this._product.isSelectedSubscription) {
      this.resumeAndBindExist(this._product.selectedProduct.type_tariff, null, cardName, cryptogram);
    } else {
      return this._subscription.regular$
        .pipe(
          take(1),
          map((regularSubscription: ISubscription) => {
            if (!regularSubscription
              || this._subscription.isTimeSubscription(this._product.selectedProduct.type)) {
              this.activateAndBind(this._product.selectedProduct.sub_code, null, cardName, cryptogram);
            } else {
              this.changeAndBind(regularSubscription.id, this._product.selectedProduct.sub_code, changeNow, null, cardName, cryptogram);
            }
          })
        ).subscribe();
    }
  }

  /**
   * Метод, проверяющий статус платежа
   * @param queryParams - гет-параметры
   */
  checkPaymentStatus(queryParams: any): Observable<boolean | UrlTree> {
    const statusTypes: any = CONSTANT.payment.status;
    if (Boolean(queryParams.status)) {
      switch (queryParams.status) {
        case statusTypes.success:
          return this.successPayment(queryParams.type, queryParams);
        case statusTypes.awaiting:
          return of(this._router.createUrlTree([this._localize.translateRoute('/steps/payment/waiting')], {queryParams}));
        case statusTypes.failed:
        default:
          this._dynamic.addDynamicComponent({
              type: 'error', data: {
                errMsg: queryParams.message || null
              },
            },
            () => this.showPaymentLoader = false);
          return of(true);
      }
    }
  }

  /**
   * Метод, проверящий тип платежа
   * @param type
   * @param queryParams
   */
  public successPayment(type: string, queryParams: any): Observable<UrlTree | boolean> {
    const constantType: any = CONSTANT.payment.successBindMethodName;
    switch (type) {
      case constantType.pay:
        // Отправит сразу на игру, если есть userLastGameCardVisited, там особый success
        if (Boolean(this._game.userLastGameCardVisited)) {
          return of(this._getGameCardOnboardingSuccessUrlTree(this._game.userLastGameCardVisited.code, queryParams));
        }
        return of(this._router.createUrlTree([this._localize.translateRoute('/steps/success')], {queryParams}));
      case constantType.bundle:
        return this._openSuccessBundle(queryParams.bundle);
      case constantType.buy:
      case constantType.specialOffer:
        // Отправит сразу на игру, если есть userLastGameCardVisited, там особый success
        if (type === constantType.buy && Boolean(this._game.userLastGameCardVisited)) {
          return of(this._getGameCardOnboardingSuccessUrlTree(this._game.userLastGameCardVisited.code, queryParams));
        }
        return this._openSuccessKey(queryParams.game);
      case constantType.preorder:
        return this._game.getKeys()
          .pipe(
            map(data => {
              const game = data.keys.find(key => key.code === queryParams.code);
              if (Boolean(game)) {
                this._dynamic.addDynamicComponent({
                  type: 'preorder', data: {
                    title: game.game_title,
                    code_game: game.code,
                  }
                });
              }
              return false;
            })
          );
      case constantType.buyPacket:
        return of(this._router.createUrlTree([this._localize.translateRoute('/account')], {queryParams: {}}));
      default:
        this._dynamic.addDynamicComponent({
          type: 'notify', data: {
            title: this._translate.instant('payment.successBind')
          }
        });
        return of(true);
    }
  }

  private _getGameCardOnboardingSuccessUrlTree(gameCode: string, queryParams: Params) {
    return this._router.createUrlTree([this._localize.translateRoute(`/game/${gameCode}`)], {queryParams});
  }

  /**
   * Метод, получающий информацию о купленной игре и открывающий модалку с ключом купленной игры
   * @param bundleCode
   * @private
   */
  private _openSuccessBundle(bundleCode: string): Observable<boolean> {
    // получаем список ключей игр
    return this.getBundleInfo([bundleCode])
      .pipe(
        // фильтруем список согласно переданому через инпут декоратор код игры
        switchMap((response: IBundleInfoResponse) => {
          if (response?.bundles?.length > 0) {
            const bundle = response?.bundles?.find(val => val.code === bundleCode);
            return combineLatest([
              of(bundle),
              this._game.getKeys().pipe(
                filter(res => Boolean(res?.keys)),
                map(res => res.keys.find(key => key.code === bundle.items.find(item => item.type === 'GameShipment').code)),
              )
            ]);
          }
          return combineLatest([of(false), of(null)]);
        }),
        mergeMap(([bundle, gameKey]: [IBundleInfo, GamesKey]) => {
            if (gameKey && bundle) {
              this._gameKey = gameKey.key;
              return combineLatest([
                this._game.getGame(gameKey.code),
                of(bundle),
                this._product.getProductByCode(bundle.items.find(item => item.type = 'ProductShipment').code)
              ]);
            }
              return of(false);
          },
        ),
        take(1),
        map((data: [GameShipment, IBundleInfo, Product]) => {
          if (data) {
            const [gameInfo, bundleInfo, productInfo] = data;
            this._dynamic.addDynamicComponent({
              type: 'successBundle',
              data: {
                bundleInfo: bundleInfo,
                productInfo: productInfo,
                gameInfo: gameInfo,
                key: this._gameKey
              }
            });
          }
          return false;
        })
      );
  }

  /**
   * Метод проверки наличия основной платежки. В зависимости от её наличия отправляем юзера
   * @param mainPs - основная платежка
   * @param changeNow - параметр, описывающий необходимость сменить подписку прямо сейчас
   */
  private _checkPsAndBuy(mainPs: IUserPayment[], psAll: IPaymentSystem[], changeNow: boolean) {
    const isPktMain = mainPs.length > 0 && mainPs[0].payment_system.code === 'PKTBalance';
    const canBuyProductForPkt = Boolean(this._product.selectedProduct.costs.filter(cost => cost.currency.code === 'PKT').length);
    if (mainPs.length && (!isPktMain || canBuyProductForPkt)) {
      this._resumeWithTariff(mainPs, changeNow);
    } else if (psAll.length === 1) {
      this.selectedPs = psAll[0].code; // указываем полученную платежку в качестве выбранной юзером
      this.buyTariff(changeNow);
    } else {
      this._openChangeTariff(changeNow);
    }
  }

  /**
   * Метод перехода к выбору платежной системы для смены тарифа
   * @param isChangeNow
   */
  private _openChangeTariff(isChangeNow: boolean) {
    this._analytic.dataLayerPush({
      event: 'event',
      eventCategory: 'payment',
      eventAction: `change tariff`,
      productCode: this._product.selectedProduct.sub_code,
    });
    const params = {
      tariff: this._product.selectedProduct.sub_code,
      isChangeNow
    };
    this._router.navigate([this._localize.translateRoute('/steps/select_ps')], {queryParams: params});
  }

  /**
   * Метод смены подписки без привязки новой пс
   * @param mainPs
   * @param isChangeNow
   */
  private _resumeWithTariff(mainPs: IUserPayment[], isChangeNow: boolean) {
    const tariffCode = this._product.selectedProduct[this._product.isSelectedSubscription ? 'code_tariff' : 'sub_code'];
    this._analytic.dataLayerPush({
      event: 'event',
      eventCategory: 'payment',
      eventAction: `select tariff resume`,
      productCode: tariffCode,
    });
    const params = {
      tariff: tariffCode,
      isChangeNow
    };

    combineLatest([this.userPs$, this.canBuyProductWidthBalancePs(),
      this.userCanPayPkt(isChangeNow), this.convertPktToRuble()])
      .pipe(
        take(1)
      )
      .subscribe((data) => {
        const modalParams = {
          isChangeNow,
          isPktMain: Boolean(data[0].filter(ps => ps.is_main && ps.payment_system.code === 'PKTBalance').length),
          isPktAvailible: data[1],
          userCanPayPkt: data[2],
          pktPrice: this.getProductPriceSellInPkt(isChangeNow),
          pktFiat: data[3],
          isTimePacket: false,
          pktDiscount: Math.ceil(100 - (data[3] * 100 / (this._product.selectedProduct.price_sell / 100)))
        };
        // если основная платёжка это PKT, но для покупки выбранного тарифа её использовать нельзя, то отправляем на привязку карты
        // поведение историческое, будем менять.
        if (modalParams.isPktMain && !modalParams.isPktAvailible) {
          this._router.navigate([this._localize.translateRoute('/steps/select_ps')], {queryParams: params});
        } else {
          this._resumeSubscribe.setModalParams(modalParams);
        }
      });
  }

  /**
   * Метод обработки ошибки попытки покупки/смены тарифа
   * @param error
   * @param returnRoute
   */
  private _catchError(error: IError, returnRoute?: string) {
    if (error.code === ERRORCODE.subscription_exists) {
      this._dynamic.addDynamicComponent({
        type: 'error', data: {
          errorMsg: this._translate.instant('subscribed.body')
        }
      }, () => this.showPaymentLoader = false);
    } else if (error.code === ERRORCODE.email_not_confirm) {
      // TODO Открываем модалку подтверждения почты
      // this.pkRouter.path(this.urls.modal, {
      //   page: 'confirm',
      // });
    } else {
      this._dynamic.addDynamicComponent({
        type: 'error', data: {
          errMsg: error.message,
          paymentType: null
        }
      }, () => {
        this.showPaymentLoader = false;
        this._router.navigate([this._localize.translateRoute(returnRoute ?? '/steps/payment')]);
      });
    }
  }

  /**
   * Метод получения идентификатора платежной системы пользователя
   * @param psCode
   */
  private _getUserPs(psCode?: string): Observable<IUserPayment> {
    return this.userPs$
      .pipe(
        map(data => {
          if (Boolean(data)) {
            let userSelectedPs: IUserPayment[] = [];
            if (Boolean(psCode)) {
              userSelectedPs = data.filter(userPs => userPs.payment_system.code === psCode);
            } else {
              userSelectedPs = data.filter(userPs => userPs.is_main);
            }
            if (userSelectedPs.length) {
              return userSelectedPs[0];
            }
          }
          return null;
        })
      );
  }

  public getUserSales(options: any = CONSTANT.params.reloadFalse): Observable<IUserSales[]> {
    const params = {
      lang: this._translate.currentLang,
      token: this._user.token,
    };
    const url = `${this._baseUrl}user/sales`;
    return this._api.post(url, params).pipe(
      map((requestData: any) => {
        this._sales.next(requestData.purchase);
        return requestData.purchase;
      }));
  }

  /**
   * Метод получения статуса оплаты  пользователя
   * @param saleId оплаты
   */

  public getUserSaleStatus(saleId: string): Observable<ISaleStatus> {
    const url = `${this._baseUrl}user/sales/status`;
    const params = {
      lang: this._translate.currentLang,
      token: this._user.token,
      sale_id: saleId,
    };
    return this._api.post(url, params).pipe(
      map((response: any) => {
        if (!response.error.code) {
          return response;
        } else {
          this._catchError(response.error);
          return null;
        }
      }));
  }

  // Я так понимаю что это отмена смены тарифа
  public cancelChangeTariff(): void {
    this._sub.regular$
      .pipe(
        take(1),
        tap((subscr: ISubscription) => {
          this.isLoading = true;
          this.change(subscr.id, subscr.code_tariff, false);
        }),
        mergeMap(() => this._sub.get())
      )
      .subscribe(() => this.isLoading = false);
  }

  public getTariffByCode(code: string, products: IProducts): Product {
    return products.products.filter(pr => pr.sub_code = code)[0];
  }

  //  Метод проверки есть ли основная платежная система

  public hasMainPaymentSystem(): boolean {
    return this.userPaymentData
      && this.userPaymentData.length > 0
      && this.getMainPaymentSystem(this.userPaymentData) !== undefined;
  }

  public getMainPaymentSystem(userPayments: IUserPayment[]): IUserPayment {
    return userPayments.filter(x => x.is_main === true)[0];
  }

  /**
   * Метод, получающий информацию о купленной игре и открывающий модалку с ключом купленной игры
   * @param gameCode
   * @private
   */
  private _openSuccessKey(gameCode: string) {
    // получаем список ключей игр
    return this._game.getKeys()
      .pipe(
        // фильтруем список согласно переданому через инпут декоратор код игры
        switchMap(response => (response?.keys && response?.keys[0]?.code === gameCode) ? of(response) : of(false)),
        mergeMap((response: GamesKeysResponse) => {
          const keys = response?.keys && response?.keys[0];
          if (keys) {
            this._gameKey = keys.key;
            return this._game.getGame(gameCode);
          }
          return of(response);
        }),
        map((gameInfo: GameShipment) => {
          if (gameInfo) {
            this._dynamic.addDynamicComponent({
              type: 'successkey',
              data: {
                gameInfo: gameInfo,
                key: this._gameKey,
                keyInfo: this._game.getGameKeyInfo(gameInfo?.code)
              }
            });
          }
          return this._router.createUrlTree([this._localize.translateRoute(`/game/${gameCode}`)]);
        })
      );
  }

  /**
   * Метод, отправляющий на форму оплаты
   * @param formUrl
   * @param gameCode - если не указан, то считаем, что покупался тариф
   * @param isChangeNow
   * @private
   */
  private _openForm(formUrl: string, gameCode: string = null, isChangeNow: boolean = false) {
    if (gameCode === PLATFORMS.Origin.code.toLowerCase()) {
      this._analytic.dataLayerPush({
        event: 'event',
        eventCategory: 'user',
        eventAction: 'origin access sale open',
      });
    }
    if (this.selectedPs !== 'Stripe') {
      window.location.href = `${formUrl}&url=${window.location.href}`;
    } else {
      // tslint:disable-next-line:prefer-const
      let [route, params] = formUrl.split('?');
      route = route.replace(/\/(ru|en|de)\//g, '/'); // TODO пока апи не поправят form_url
      const search: any = Boolean(params) ? JSON.parse('{"' + decodeURI(params.replace(/&/g, '","').replace(/=/g, '": "')) + '"}') : {};
      if (gameCode) {
        search.game = gameCode;
      }
      if (this._product.selectedProduct) {
        search.tariff = this._product.selectedProduct.sub_code;
        search.isChangeNow = isChangeNow;
      }
      this._router.navigate([this._localize.translateRoute(route)], {queryParams: search});
    }
  }

  public requestCashOut(value: number, currencyCode: string, outputAddress: string) {
    const url = `${this._baseUrl}user/ps/withdraw`;
    const params = {
      lang: this._translate.currentLang,
      token: this._user.token,
      value: value,
      currency_code: currencyCode,
      output_address: outputAddress
    };
    return this._api.post(url, params);
  }

  public getCurrentUserBalance(ps) {
    let balance = 0;
    if (Boolean(ps) && Boolean(ps.current_balance)) {
      balance = Number(ps.current_balance);
      const digitsCount = ps.currency.kopeks_digits_count;
      if (Boolean(digitsCount)) {
        balance = balance / (10 ** digitsCount);
      }
    }
    return balance;
  }
}
