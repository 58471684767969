import { Routes } from '@angular/router';
import { ErrorResponseComponent } from './shared/components/error-pages/error-response/error-response.component';
import { ErrorUnavailableComponent } from './shared/components/error-pages/error-unavailable/error-unavailable.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'games'
  },
  {
    path: 'users',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'games',
    loadChildren: () => import('./games/modules/games-catalog/games-catalog.module').then(m => m.GamesCatalogModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'game',
    loadChildren: () => import('./games/modules/game-card/game-card.module').then(m => m.GameCardModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'errorResponse',
    component: ErrorResponseComponent
  },
  {
    path: 'errorUnavailable',
    component: ErrorUnavailableComponent
  },
  {
    path: 'union',
    loadChildren: () => import('./union/union.module').then(m => m.UnionModule)
  },
  // Редиректы
  {
    path: 'auth',
    pathMatch: 'full',
    redirectTo: 'users/auth'
  },
  {
    path: 'about',
    pathMatch: 'full',
    redirectTo: 'documents/about'
  },
  {
    path: 'technology',
    pathMatch: 'full',
    redirectTo: 'documents/technology'
  },
  {
    path: 'reveal',
    pathMatch: 'full',
    redirectTo: 'documents/reveal'
  },
  {
    path: 'confirm/:token',
    pathMatch: 'full',
    redirectTo: 'users/confirm/:token'
  },
  {
    path: 'set_pass/:token',
    pathMatch: 'full',
    redirectTo: 'users/set_pass/:token'
  },
  // Редиректы
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/games'
  }
];

