<pk-header *ngIf="isVisibleHeader"></pk-header>
<div class="pk-wrapper"
     [ngClass]="{
        'pk-wrapper--catalog': router.url.includes('games') && !router.url.includes('games/client/download'),
        'pk-wrapper--catalog-fixed': !!isFixedHeader,
        'pk-wrapper--without-header': !isVisibleHeader
     }">
  <ng-container *ngIf="!updateAfterLangChanged">
    <router-outlet></router-outlet>
  </ng-container>
</div>
<!--Проверка задолжености-->
<ng-container *ngIf="isBrowser">
  <pk-debt></pk-debt>
  <pk-error-response *ngIf="utils?.requestErrorType === 'errorResponse'"></pk-error-response>
  <pk-error-unavailable *ngIf="utils?.requestErrorType === 'errorUnavailable'"></pk-error-unavailable>
  <pk-resume-subscribe *ngIf="resumeSubscribe.visible"></pk-resume-subscribe>
  <pk-payment-loader></pk-payment-loader>
  <aside id="freshChatContainer" #freshChatContainer></aside>
</ng-container>
<pk-inline-svg></pk-inline-svg>
