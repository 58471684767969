import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener, Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UserService } from '../../../user/services/user.service';
import { SubscriptionService } from '../../../user/services/subscription.service';
import {
  NavigationEnd,
  RouteConfigLoadStart,
  Router,
  RouterEvent
} from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { PaymentService } from '../../../payments/services/payment.service';
import { Subject } from 'rxjs';
import { debounceTime, mergeMap, takeUntil } from 'rxjs/operators';
import { ISubscription } from '../../../user/interfaces/subscription';
import { IUserInfo } from '../../../user/interfaces/user-info';
import { SupportService } from '../../services/support.service';
import { TranslateService } from '@ngx-translate/core';
import { GamesService } from '../../services/games.service';
import { CookieWrapperService } from '../../services/cookie-wrapper.service';
import { FreshChatService } from '../../modules/fresh-chat/fresh-chat.service';
import { PushService } from '../../services/push.service';
import { LangService } from '../../services/lang.service';
import { HEADER_HIDE_SEARCH_FORM_COOKIE_KEY } from './header.const';
import { AnalyticService } from '../../services/analytic.service';
import { GamesCategoriesService } from '../../../games/modules/games-catalog/services/games-categories.service';
import { isPlatformBrowser } from '@angular/common';
import { CONSTANT } from '../../constant/CONSTANT';
import { LANGUAGE, LanguageTypes } from '../../constant/LANG';

@Component({
  selector: 'pk-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isActiveSearchForm;
  public showSupportDropdown = false;
  public showLangDropdown = false;
  public showSearchFormTip = true;
  public isBrowser = isPlatformBrowser(this._platformId);
  public isVisibleRolledPanel = false;
  public isClosedRolledPanel = false;

  public isLoadUser = false;

  public showUserInfoDropdown = false;
  public isOpenedSearchForm;

  public userInfo: IUserInfo = null;

  public userSubscriptions: ISubscription[] = null;

  // Constant
  public readonly constLanguage = LANGUAGE;

  @ViewChild('elOpenSelect') el: ElementRef;

  // Отписки
  private _unsubscribe$: Subject<any> = new Subject();

  @HostListener('window:click', ['$event']) needCloseSelect(e: MouseEvent) {
    //  Отслеживаем что мы сделали клик на свободное место  чтоб закрыть селект
    const curEl = (e.target || e.srcElement || e.currentTarget as Element);
    if (this.el && curEl !== this.el.nativeElement) {
      this.showSupportDropdown = false;
      this.showUserInfoDropdown = false;
      this.showLangDropdown = false;
      this._cdr.markForCheck();
    }
  }

  constructor(
    public user: UserService,
    public push: PushService,
    public support: SupportService,
    private _subscription: SubscriptionService,
    private _router: Router,
    private _cookie: CookieWrapperService,
    private _payment: PaymentService,
    private _freshChat: FreshChatService,
    private _localize: LocalizeRouterService,
    private _games: GamesService,
    private _lang: LangService,
    private _translate: TranslateService,
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _cdr: ChangeDetectorRef,
    private _analytic: AnalyticService,
    private _gamesCategories: GamesCategoriesService,
    @Inject(PLATFORM_ID) private _platformId: Object,
  ) {
  }

  ngOnInit() {
    this._subscribeRouterEvents();
    this.user.userInfo$.pipe(
      mergeMap((data) => {
        this.userInfo = data;
        return this._subscription.data$;
      }),
      debounceTime(100),
      takeUntil(this._unsubscribe$)
    ).subscribe(sub => {
      this.userSubscriptions = sub;
      this.isLoadUser = true;
      this._cdr.markForCheck();
    });
    this.showSearchFormTip = !this._cookie.get(HEADER_HIDE_SEARCH_FORM_COOKIE_KEY);
  }

  ngOnDestroy() {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }


  public getSupportLink(): string {
    return `https://playkey.freshdesk.com/support/home`;
  }

  public logout(e): void {
    this.showUserInfoDropdown = false;
    this.user.logout();
    // Очищаем информацию о купленных играх пользователя
    this._games.getKeys().subscribe();
    // очищаем обьект памяти категорий
    this._games.resetGamesDump();
    this._gamesCategories.getCategoriesAndGenres();
    // возврат скрола к началу документа
    this._games.setScrollPositionGlobal(0, 0);
    // Переход на главную страницу каталога, после возврата скрола (для этого setTimeout)
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      return this._router.navigate([this._localize.translateRoute('/games')]);
    });
  }

  public hasSubscribe(): boolean {
    return Boolean(this.userSubscriptions) && this.userSubscriptions.length && this.userSubscriptions[0].is_active_tariff;
  }

  public canBuyOverlimit(): boolean {
    const userSub = this.userSubscriptions?.length > 0 ? this.userSubscriptions[0] : null;

    // Если нет активной подписки или она часовая или в ней нет возможности докупить сверхлимит -> не показываем кнопку
    if (!userSub || userSub.type === CONSTANT.subscription.type.time || !userSub.is_active_tariff || userSub.time_packets.length === 0) {
      return false;
    }

    // Тариф с "ночным" безлимитом + с обычным лимитом и возможностью докупить сверхлимит для игры днем
    const isNewNightSub = userSub.type !== CONSTANT.subscription.type.regularPartOfDay
      && Boolean(userSub.start_action)
      && Boolean(userSub.finish_action);

    // Для  нового "ночного"(не стандартного RegularPartOfDay) показываем всегда,
    // для остальных - если остаалось 30 минут и меньше
    return isNewNightSub || userSub.isStartEndPeriod;
  }

  public getLangImg(lang?: LanguageTypes): string {
    if (!lang) {
      lang = this._lang.getLang();
    }
    return Boolean(this._lang.langData[lang]) ? `https://vkplaycloud.mrgcdn.ru/img/playkeynet/new/footer/${this._lang.langData[lang].imgName}` : '';
  }

  /**
   * Выбор языка
   * @param {string} lang
   */
  public changeLang(lang: LanguageTypes) {
    this._cookie.put('lang', lang);
    this._localize.changeLanguage(lang);
    this._freshChat.setFreshChatLocale(lang);
    this.showLangDropdown = false;
  }

  public onUserMenuAccountClick(e) {
    this.showUserInfoDropdown = false;
    this.showSupportDropdown = false;
    this.showLangDropdown = false;
    this._cdr.markForCheck();
    return this._router.navigate([this._localize.translateRoute('/account')]);
  }

  /**
   * Смена значения showAccountInfo
   * @param currentState
   */
  public toggleUserInfoDropdown(currentState, ev) {
    this.showUserInfoDropdown = !currentState;
    ev.stopPropagation();
    this._cdr.markForCheck();
  }

  /**
   * Смена статуса уведомления
   */
  public toggleNotifyState() {
    this.push.isNotAcceptNotify() === true ? this.push.subscribe() : this.push.unsubscribe();
  }

  /**
   * * Смена значения showLangDropdown
   * @param currentState
   */
  public toggleLangDropdown(currentState, ev) {
    this.showLangDropdown = !currentState;
    ev.stopPropagation();
    this._cdr.markForCheck();
  }

  /**
   * * Смена значения showSupportMenu
   * @param currentState
   */
  public toggleSupportMenuDropdown(currentState, ev) {
    this.showSupportDropdown = !currentState;
    ev.stopPropagation();
    this._cdr.markForCheck();
  }

  public searchFormChangeState(state) {
    this._cdr.detectChanges();
    if (this.showSearchFormTip) {
      this.onCloseSearchTip();
    }
    this.isOpenedSearchForm = state;
    this._cdr.markForCheck();
  }

  public onRolledHeaderPanelCloseButtonClick(value: boolean) {
    if (value) {
      this._setCookieVisibleRolledPanel();
    }
    this._renderer.removeClass(document.body, 'header--with-rolled-header-panel');
    this._renderer.removeStyle(this._el.nativeElement, 'animation');
    const closeAnimationTimeoutFn = setTimeout(() => {
      this._renderer.setStyle(this._el.nativeElement, 'animation', '1s rolled-header-panel-slide-in linear reverse forwards');
    });
    const showRolledHeaderPanelTimeoutFn = setTimeout(() => {
      this.isVisibleRolledPanel = false;
      this.isClosedRolledPanel = true;
      this._renderer.removeStyle(this._el.nativeElement, 'animation');
      this._cdr.markForCheck();
      clearTimeout(showRolledHeaderPanelTimeoutFn);
      clearTimeout(closeAnimationTimeoutFn);
    }, 1000);

  }

  public onRolledHeaderPanelOkButtonClick() {
    this.onRolledHeaderPanelCloseButtonClick(true);
    this._router.navigate([this._localize.translateRoute('/documents/how-use-launchers')]);
  }

  private _setCookieVisibleRolledPanel() {
    this._cookie.put('notVisibleRolledPanel', 'true');
  }

  /**
   * Обработка закрытия подсказки формы поиска
   */
  public onClickSearchTip() {
    this._closeSearchTipAndPutCookie();
    this.isActiveSearchForm = true;
    this.searchFormChangeState(true);
  }

  /**
   * Обработка закрытия подсказки формы поиска
   */
  public onCloseSearchTip() {
    this._closeSearchTipAndPutCookie();
  }

  /**
   * Закрытие подсказки формы поиска и добавление куки, чтобы не показывать подсказку в дальнейшем
   * @private
   */
  private _closeSearchTipAndPutCookie() {
    this.showSearchFormTip = false;
    this._cookie.put(HEADER_HIDE_SEARCH_FORM_COOKIE_KEY, 'true');
  }


  /**
   * Подписка на изменения роута и установка значений для свойств зависящих от смены роута
   * @private
   */
  private _subscribeRouterEvents() {
    this._router.events.pipe(
      takeUntil(this._unsubscribe$))
      .subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.isActiveSearchForm = this.isOpenedSearchForm = event.url.includes('/search');
          this.showUserInfoDropdown = false;
          this._cdr.markForCheck();
        }
      });
  }
}
