<pk-header-rolled-panel
  *ngIf="isVisibleRolledPanel"
  [panelContent]="'howUseLaunchers.banner.content' | translate"
  [okButtonText]="'howUseLaunchers.banner.button' | translate"
  (closeButtonClick)="onRolledHeaderPanelCloseButtonClick($event)"
  (okButtonClick)="onRolledHeaderPanelOkButtonClick()"></pk-header-rolled-panel>
<div class="header">
  <a class="header__logo tooltip--left" [routerLink]="['/' | localize]">
    <img src="//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/logo.svg" alt="PLAYKEY"
         pkTooltip [tooltipContent]="'header.toMainTitle' | translate"/>
  </a>
  <nav class="header-menu">
    <!-- Кнопки управления пользователя -->
    <div class="header-menu-right-navigation" [ngClass]="{'relative': !isLoadUser}">
      <pk-loader *ngIf="!isLoadUser"></pk-loader>
      <ng-container
        *ngIf="isLoadUser && user.token; then userAuthorizedMenuTpl else userUnAuthorizedMenuTpl"></ng-container>
    </div>
  </nav>
</div>

<!-- Не авторизован -->
<ng-template #userUnAuthorizedMenuTpl>
  <ng-container [ngTemplateOutlet]="searchFormTpl"></ng-container>
  <ng-container [ngTemplateOutlet]="langSelectorTpl"></ng-container>
  <div class="header-menu-right-navigation__item">
    <button class="header-menu-navigation-button header-menu-navigation-button--login"
            [routerLink]="['/union'] | localize">
      <span>{{ 'login' | translate }}</span>
    </button>
  </div>
</ng-template>

<!-- Авторизован -->
<ng-template #userAuthorizedMenuTpl>
  <ng-container [ngTemplateOutlet]="searchFormTpl"></ng-container>
  <ng-container [ngTemplateOutlet]="langSelectorTpl"></ng-container>
  <ng-container [ngTemplateOutlet]="notifyTpl"></ng-container>~
  <!-- Имя пользователя -->
  <div class="header-menu-right-navigation__item tooltip--right"
       (mouseleave)="showUserInfoDropdown = false">
    <!-- Выпадающее окно -->
    <ng-container *ngIf="showUserInfoDropdown === true && isBrowser">
      <pk-dropdown-menu [menuClass]="'dropdown-menu--header dropdown-menu--user'">
        <pk-dropdown-menu-item (itemClick)="onUserMenuAccountClick($event)">
          {{ 'lk' | translate }}
        </pk-dropdown-menu-item>
        <pk-dropdown-menu-item (itemClick)="logout($event)">
          {{ 'logout' | translate }}
        </pk-dropdown-menu-item>
      </pk-dropdown-menu>
    </ng-container>
    <button *ngIf="!!userInfo?.email"
            #elOpenSelect
            class="header-menu-navigation-button header-menu-navigation-button--user-name"
            (click)="toggleUserInfoDropdown(showUserInfoDropdown, $event)">
      <span pkTooltip
            [tooltipContent]="!showUserInfoDropdown ? userInfo?.email : undefined">{{ userInfo?.email | emailGood }}</span>
    </button>
  </div>
</ng-template>
<ng-template #langSelectorTpl>
  <div class="header-menu-right-navigation__item header-menu-right-navigation__item--lang-selector"
       (mouseleave)="showLangDropdown = false">
    <pk-dropdown-menu *ngIf="showLangDropdown === true && isBrowser"
                      [menuClass]="'dropdown-menu--lang-selector'">
      <pk-dropdown-menu-item (itemClick)="changeLang(constLanguage.RU);"
                             [itemClass]="'dropdown-menu-item--with-icon'">
        <img [src]="getLangImg(constLanguage.RU)"
             width="16"
             alt="Русский"/>
        Русский
      </pk-dropdown-menu-item>
      <pk-dropdown-menu-item (itemClick)="changeLang(constLanguage.EN);"
                             [itemClass]="'dropdown-menu-item--with-icon'">
        <img [src]="getLangImg(constLanguage.EN)"
             width="16"
             alt="English"/>
        English
      </pk-dropdown-menu-item>
    </pk-dropdown-menu>
    <button class="header-menu-navigation-button header-menu-navigation-button--show-langs"
            pkTooltip
            [tooltipContent]="'header.tooltips.language' | translate"
            (click)="toggleLangDropdown(showUserInfoDropdown, $event)">
      <img [src]="getLangImg()"
           alt="{{ 'footer.changeTranslation' | translate }}">
    </button>
  </div>
</ng-template>
<ng-template #notifyTpl>
  <div class="header-menu-right-navigation__item"
       *ngIf="user.isCis() && !!userInfo">
    <button class="header-menu-navigation-button header-menu-navigation-button--notify"
            pkTooltip
            [tooltipContent]="push.isNotAcceptNotify() ? ('push.accountSubscribe' | translate) : ('push.accountUnsubscribe' | translate)"
            (click)="toggleNotifyState()">
      <img
        [src]="push.isNotAcceptNotify() ? '//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/icons/notify-bell-off.svg' : '//vkplaycloud.mrgcdn.ru/img/playkeynet/design2020/icons/notify-bell.svg'"
        alt=""/>
    </button>
  </div>
</ng-template>
<ng-template #searchFormTpl>
  <div class="header-menu-right-navigation__item header-menu-right-navigation__item--search-form"
       [class.header-menu-right-navigation__item--search-form-active]="isOpenedSearchForm">
    <pk-search-form pkTooltip
                    [tooltipContent]="!isOpenedSearchForm ? ('header.tooltips.search-form' | translate) : undefined"
                    (changeState)="searchFormChangeState($event)"
                    [isActive]="isActiveSearchForm"></pk-search-form>
    <pk-tip [tipClass]="'tip--search-form'"
            [timeout]="3000"
            *ngIf="showSearchFormTip && isBrowser"
            (tipClicked)="onClickSearchTip()"
            (tipClosed)="onCloseSearchTip()">
      <svg class="search-icon search-icon--black">
        <use xlink:href="#search-icon"></use>
      </svg>
      <span>{{'tips.search-form-text' | translate}}</span>
    </pk-tip>
  </div>
</ng-template>
