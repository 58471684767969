import { Injectable } from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import { ApiService } from './api.service';
import { UserService } from '../../user/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import {flatMap, map, take} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {ICurrencyRate} from '../../interfaces/currency-rate';
import { IUserPayment } from '../../payments/interfaces/user-payment-system';
import { ICurrency, ICurrencyResponse } from '../../interfaces/currency';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
/**
 * Список валют в data$
 * если необходимо обновить - дернуть принудительно fetchCurrencies
 */
export class CurrencyService {
  private _baseUrl = `${environment.apiPathPrimary}currencies/`;
  private dataSubject = new ReplaySubject<ICurrency[]>(1);
  public data$: Observable<ICurrency[]> = this.dataSubject.asObservable();
  public selectedCurrency = null;
  constructor(private _api: ApiService,
              private _user: UserService,
              private _translate: TranslateService) {
  }

  /**
   * Возвращает курс одной валюты относительно другой
   * @param currencyFrom
   * @param currencyTo
   * @return Observable<number>
   */
  public getRate (currencyFrom: string, currencyTo: string): Observable<number> {
    const params = new HttpParams({
      fromObject: {
        currency_from: currencyFrom,
        currency_to: currencyTo,
        lang: this._translate.currentLang,
      }
    });
    return this._api.get(`${this._baseUrl}rate`, params)
      .pipe(
        map((data: ICurrencyRate) => {
          if (!data.error.code) {
            return data.currency_rate;
          } else {
            return null;
          }
        })
      );
  }

  /**
   * Метод устанавливает основную валюту пользователя на основе
   * основной привязанной платежки (да, это звучит потрясающе)
   * @param userPs
   */
  public setCurrencyFromUserPs(userPs: IUserPayment[]) {
    if (userPs.length) {
      const mainPs = userPs.filter(ps => ps.is_main);
      this.selectedCurrency = mainPs.length ? mainPs[0].currency : null;
    }
  }

  /**
   * Определяем основную валюту пользователя по его региону
   * @param currencies
   * @private
   */
  private _setRegionCurrency(currencies: ICurrency[]) {
    this.selectedCurrency = currencies.length ? currencies[0] : null;
  }
}
