import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductService } from '../../../payments/services/product.service';
import { CurrencyService } from '../../services/currency.service';
import { PaymentService } from '../../../payments/services/payment.service';
import { ResumeSubscribeService } from '../../services/resume-subscribe.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SubscriptionService } from '../../../user/services/subscription.service';
import { combineLatest, of, Subscription } from 'rxjs';
import { mergeMap, map, take } from 'rxjs/operators';
import { ISubscription } from '../../../user/interfaces/subscription';
import { IUserPayment } from '../../../payments/interfaces/user-payment-system';
import { AccountService } from '../../../account/service/account.service';
import { ProductCost } from '../../../games/interfaces/product';

@Component({
  selector: 'pk-resume-subscribe',
  templateUrl: './resume-subscribe.component.html',
  styleUrls: ['./resume-subscribe.component.scss']
})
export class ResumeSubscribeComponent implements OnInit, OnDestroy {
  isLoading = false;
  isLoadPayment = false;
  currency;
  paymentsUser: IUserPayment[];
  productCost: ProductCost;
  private _unsubscribe: Subscription = new Subscription();

  constructor(
    public product: ProductService,
    public resumeSubscribe: ResumeSubscribeService,
    private _currency: CurrencyService,
    private _router: Router,
    private _localize: LocalizeRouterService,
    private _subscription: SubscriptionService,
    private _payment: PaymentService,
    private _account: AccountService
  ) {
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this._unsubscribe) {
      this._unsubscribe.unsubscribe();
    }
  }

  getFormattedPrice(price: number, isWithoutSpace: boolean = false, currency?: string): string {
    const resultCurrency = (this.currency.filter(x => x.code === currency))[0] || this.currency[0];
    const floatPrice = price / 100;
    if (isWithoutSpace === true) {
      resultCurrency.format = resultCurrency.format.replace(' ', '');
    }
    return resultCurrency.format
      .replace('{symbol}', resultCurrency.sign)
      .replace('{price}', floatPrice.toString());
  }

  closeModal() {
    this.resumeSubscribe.visible = false;
  }

  openSelectPs() {
    const timePacket = this.resumeSubscribe.params.isTimePacket ? this.product.selectedProduct.code : null;
    const tariffCode = !timePacket ? this.product.selectedProduct[this.product.isSelectedSubscription ? 'code_tariff' : 'sub_code'] : null;
    this._router.navigate([this._localize.translateRoute('/steps/select_ps/')], {
      queryParams: {
        isChangeNow: this.resumeSubscribe.params.isChangeNow,
          timePacket: timePacket,
          tariff: tariffCode
      }
    });
  }

  goAndBuyPkt() {
    this.closeModal();
    this._router.navigate([this._localize.translateRoute('/account')], {
      queryParams: {
        openPkt: true
      }
    });
  }

  paymentPS() {
    this.isLoadPayment = true;
    if (this.product.isSelectedSubscription) {
      this._unsubscribe.add(
        this._payment.resumeExist(this.product.selectedProduct.type_tariff)
        .subscribe((data) => {
          this.isLoadPayment = false;
          this.closeModal();
        }));
    } else {
      this._unsubscribe.add(this._subscription.regular$
        .pipe(
          take(1),
          map((regular: ISubscription) => {
            if (!regular || this._subscription.isTimeSubscription(this.product.selectedProduct.type)) {
              return this._payment.activate(this.product.selectedProduct.sub_code);
            }
            return this._payment.change(regular.id, this.product.selectedProduct.sub_code, this.resumeSubscribe.params.isChangeNow);
          })
        )
        .subscribe((data) => {
          this.isLoadPayment = false;
          this.closeModal();
        }));
    }
  }

  buyOverlimit() {
    this._unsubscribe.add(this._payment.buyTimePacket(this.product.selectedProduct.code)
      .pipe(
        mergeMap(data => data ? this._subscription.get() : of(null))
      )
      .subscribe((data) => {
        this.closeModal();
        this._router.navigate([this._localize.translateRoute('/steps/success/')]);
      }));
  }

  private _filterPaymentsUser(data) {
    return data.filter(ps => this.resumeSubscribe.params.isPktAvailible || ps.payment_system.code !== 'PKTBalance');
  }
}
